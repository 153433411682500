import React, { useState, useEffect } from "react";
import LaptopHomeImage from "../imagenes/laptop-5673901_1280.jpg";

function LeyendaHome() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Simular la carga de la página
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 1000); // Cambia el valor del retraso según tus necesidades

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className="hero min-h-screen relative"
      style={{ backgroundImage: `url(${LaptopHomeImage})` }}
    >
      <div className="hero-overlay bg-opacity-60 shadow-xl"></div>
      <div className="hero-content text-center text-neutral-content">
        <div className="max-w-screen-md mx-auto px-6">
          <h3 className={`mb-6 text-6xl font-bold ${loaded ? 'opacity-100 transition-opacity duration-1000 delay-500 transform translate-x-0' : 'opacity-0'}`}>
            Codificando ideas, diseñando experiencias e impulsando resultados
          </h3>
        </div>
      </div>
    </div>
  );
}

export default LeyendaHome;
