import React, { useState, useEffect } from "react";

function Clientes() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const images = [
    require("../imagenes/HocifCost.png"),
    require("../imagenes/palapas.png"),
    require("../imagenes/ESMERALD.png"),
    require("../imagenes/morris.png"),
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Cambia de imagen cada 3 segundos

    return () => clearInterval(interval); // Limpia el intervalo cuando el componente se desmonte
  }, [images.length]);

  return (
    <div className="clientes bg-neutral-content card-body rounded-box p-4 md:p-8">
      <h3 className="mb-6 text-3xl md:text-6xl font-bold text-primary-content">Nuestros Clientes</h3>
      <div className="w-full md:w-84 carousel rounded-box">
        {images.map((image, index) => (
          <div
            key={index}
            className={`carousel-item w-full ${
              index === currentIndex ? "block" : "hidden"
            }`}
          >
            <div className="mockup-phone">
              <div className="camera"></div>
              <div className="display">
                <div className="artboard artboard-demo phone-1 bg-white">
                  <img src={image} alt="Soluciones Web" className="" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Clientes;
