import "./App.css";
import LeyendaHome from "./componentes/LeyendaHome";
import Servicios from "./componentes/Servicios";
import Contacto from "./componentes/Contacto";
import Clientes from "./componentes/Clientes";
import QuienesSomos from "./componentes/QuienesSomos";
import React, { useState, useRef, useEffect } from "react";
import { useSpring, animated } from "react-spring";

function App() {
  const [isVisible, setIsVisible] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const serviciosRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      if (serviciosRef.current) {
        const top = serviciosRef.current.getBoundingClientRect().top;
        setIsVisible(top < window.innerHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const animationProps = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? "translateX(0)" : "translateX(100%)",
    config: { duration: 1500 },
  });

  return (
    <div className="App" id="App">
      <header className="navbar bg-base-100 shadow-xl p-4">
        <div className="flex items-center justify-between w-full">
          <div className="w-32 rounded">
            <img src={require(`../src/imagenes/logo.png`)} alt="Logo" />
          </div>
          <div className="flex items-center">
            <div className="hidden md:block">
              <ul className="menu menu-horizontal px-1">
                <li><a href="#App">Inicio</a></li>
                <li><a href="#contenedorServicios">Servicios</a></li>
                <li><a href="#contenedorNosotros">Nosotros</a></li>
                <li><a href="#contenedorClientes">Clientes</a></li>
                <li><a href="#contenedorContacto">Contacto</a></li>
              </ul>
            </div>
            <button className="btn btn-square btn-ghost md:hidden" onClick={() => setMenuOpen(!menuOpen)}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            </button>
          </div>
        </div>
        {menuOpen && (
          <div className="md:hidden">
            <ul className="menu menu-vertical mt-2 p-2 shadow bg-base-100 rounded-box w-full">
              <li><a href="#App" onClick={() => setMenuOpen(false)}>Inicio</a></li>
              <li><a href="#contenedorServicios" onClick={() => setMenuOpen(false)}>Servicios</a></li>
              <li><a href="#contenedorNosotros" onClick={() => setMenuOpen(false)}>Nosotros</a></li>
              <li><a href="#contenedorClientes" onClick={() => setMenuOpen(false)}>Clientes</a></li>
              <li><a href="#contenedorContacto" onClick={() => setMenuOpen(false)}>Contacto</a></li>
            </ul>
          </div>
        )}
      </header>
      <div className="contenedorLeyenda">
        <LeyendaHome />
      </div>
      <div className="contenedorServicios" id="contenedorServicios">
        <div className="" ref={serviciosRef}>
          <animated.div style={animationProps}>
            <Servicios />
          </animated.div>
        </div>
      </div>
      <div className="contenedorNosotros p-10" id="contenedorNosotros">
        <QuienesSomos/>
      </div>
      <div className="contenedorClientes" id="contenedorClientes">
        <Clientes/>
      </div>
      <div className="contenedorContacto" id="contenedorContacto">
        <Contacto/>
      </div>
      <footer className="footer p-10 bg-neutral text-neutral-content">
        <aside>
          <div className="w-32 rounded">
            <img src={require(`../src/imagenes/logo.png`)} alt="Logo" />
          </div>
          <p>
            DesTDis S.A.S.
            <br />
            Proporcionando tecnología confiable desde 2023
          </p>
        </aside>
        <nav>
          <h6 className="footer-title">Social</h6>
          <div className="grid grid-flow-col gap-4">
            <a>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
                <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
              </svg>
            </a>
            <a>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
                <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
              </svg>
            </a>
            <a>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
                <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path>
              </svg>
            </a>
          </div>
        </nav>
      </footer>
    </div>
  );
}

export default App;
