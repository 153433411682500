import React, { useEffect } from "react";

function Contactos() {
  useEffect(() => {
    const script1 = document.createElement("script");
    script1.src = "https://www.google.com/recaptcha/api.js";
    document.body.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerHTML = `
      function timestamp() {
        var response = document.getElementById("g-recaptcha-response");
        if (response == null || response.value.trim() == "") {
          var elems = JSON.parse(document.getElementsByName("captcha_settings")[0].value);
          elems["ts"] = JSON.stringify(new Date().getTime());
          document.getElementsByName("captcha_settings")[0].value = JSON.stringify(elems);
        }
      }
      setInterval(timestamp, 500);
    `;
    document.body.appendChild(script2);
  }, []);

  return (
    <div className="hero min-h-screen bg-base-200">
      <div className="hero-content flex-col lg:flex-row-reverse">
        <div className="lg:w-1/2 lg:px-6">
          <div className="text-center lg:text-left">
            <h1 className="text-5xl font-bold">CONTACTO</h1>
            <p className="py-6">
              Para mayor información y poder brindarte un mejor servicio. Ayúdanos a llenar el siguiente formulario. ¡Gracias!
            </p>
          </div>
        </div>
        <div className="lg:w-1/2 lg:px-6">
          <div className="card shrink-0 w-full max-w-xl shadow-xl bg-base-100">
            <form
              action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00DHu000002ba0P"
              method="POST"
              className="card-body grid grid-cols-2 gap-4"
            >
              <input
                type="hidden"
                name="captcha_settings"
                value='{"keyname":"DesTDis","fallback":"true","orgId":"00DHu000002ba0P","ts":""}'
              />
              <input type="hidden" name="oid" value="00DHu000002ba0P" />
              <input
                type="hidden"
                name="retURL"
                value="http://www.destdis.mx"
              />

              <div className="form-control col-span-1">
                <label className="label">
                  <span className="label-text">Nombre</span>
                </label>
                <input
                  id="first_name"
                  maxLength="40"
                  name="first_name"
                  size="20"
                  type="text"
                  className="input input-bordered"
                  required
                />
              </div>

              <div className="form-control col-span-1">
                <label className="label">
                  <span className="label-text">Apellidos</span>
                </label>
                <input
                  id="last_name"
                  maxLength="80"
                  name="last_name"
                  size="20"
                  type="text"
                  className="input input-bordered"
                  required
                />
              </div>

              <div className="form-control col-span-1">
                <label className="label">
                  <span className="label-text">Email</span>
                </label>
                <input
                  id="email"
                  maxLength="80"
                  name="email"
                  size="20"
                  type="email"
                  className="input input-bordered"
                  required
                />
              </div>

              <div className="form-control col-span-1">
                <label className="label">
                  <span className="label-text">Teléfono</span>
                </label>
                <input
                  id="phone"
                  maxLength="40"
                  name="phone"
                  size="20"
                  type="text"
                  className="input input-bordered"
                />
              </div>

              <div className="form-control col-span-1">
                <label className="label">
                  <span className="label-text">Compañía</span>
                </label>
                <input
                  id="company"
                  maxLength="40"
                  name="company"
                  size="20"
                  type="text"
                  className="input input-bordered"
                />
              </div>

              <div className="form-control col-span-1">
                <label className="label">
                  <span className="label-text">Ciudad</span>
                </label>
                <input
                  id="city"
                  maxLength="40"
                  name="city"
                  size="20"
                  type="text"
                  className="input input-bordered"
                />
              </div>

              <div className="form-control col-span-2">
                <label className="label">
                  <span className="label-text">Estado/Provincia</span>
                </label>
                <input
                  id="state"
                  maxLength="20"
                  name="state"
                  size="20"
                  type="text"
                  className="input input-bordered"
                />
              </div>

              <div className="form-control col-span-2">
                <div
                  className="g-recaptcha"
                  data-sitekey="6LcwUPkpAAAAAFRAzA_dohSqULy7DkGTZfB9flyD"
                ></div>
              </div>

              <div className="form-control col-span-2 mt-6">
                <button type="submit" className="btn btn-primary">
                  Enviar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contactos;
