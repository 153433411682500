import React, { useState, useEffect } from "react";
import "../estilos/Servicios.css";

function Servicios() {
  const [showDiv, setShowDiv] = useState(false);

  useEffect(() => {
    // Muestra el div después de un breve retraso al cargar la página
    const timer = setTimeout(() => {
      setShowDiv(true);
    }, 500); // Puedes ajustar el tiempo según sea necesario

    // Limpia el temporizador al desmontar el componente
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={`card min-h-screen glass bg-neutral-content ${
        showDiv ? "slide-in" : ""
      }`}
    >
      <div className="card-body">
        <h3 className="mb-6 text-3xl md:text-6xl font-bold text-primary-content text-center">
          Nuestros Servicios
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="card rounded-box place-items-center">
            <div className="card w-full md:w-80 lg:w-96 neutral-content shadow-xl text-primary-content">
              <figure className="px-10 pt-10">
                <img
                  src={require("../imagenes/SoluicionesWeb.jpg")}
                  alt="Soluciones Web"
                  className="rounded-xl"
                />
              </figure>
              <div className="card-body items-center text-center">
                <h2 className="card-title">Soluciones Web</h2>
                <p>Diseño y lanzamiento de Sitios Web de calidad</p>
              </div>
            </div>
          </div>

          <div className="card rounded-box place-items-center text-primary-content">
            <div className="card w-full md:w-80 lg:w-96 neutral-content shadow-xl">
              <figure className="px-10 pt-10">
                <img
                  src={require("../imagenes/design.jpg")}
                  alt="Diseño"
                  className="rounded-xl"
                />
              </figure>
              <div className="card-body items-center text-center">
                <h2 className="card-title">Diseño a tu medida</h2>
                <p>Servicios y soluciones de Diseño Gráfico </p>
              </div>
            </div>
          </div>

          <div className="card rounded-box place-items-center">
            <div className="card w-full md:w-80 lg:w-96 neutral-content shadow-xl text-primary-content">
              <figure className="px-10 pt-10">
                <img
                  src={require("../imagenes/hosting.jpg")}
                  alt="Hosting Email y Web"
                  className="rounded-xl"
                />
              </figure>
              <div className="card-body items-center text-center">
                <h2 className="card-title">Hosting Email y Web</h2>
                <p>Alojamiento web y dominios personalizados</p>
              </div>
            </div>
          </div>

          <div className="card rounded-box place-items-center text-primary-content">
            <div className="card w-full md:w-80 lg:w-96 neutral-content shadow-xl">
              <figure className="px-10 pt-10">
                <img
                  src={require("../imagenes/marketing.jpg")}
                  alt="Marketing Digital/Redes Sociales"
                  className="rounded-xl"
                />
              </figure>
              <div className="card-body items-center text-center">
                <h2 className="card-title">
                  Marketing Digital/Redes Sociales
                </h2>
                <p>Construir y fortalecer la presencia de la marca</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Servicios;
