import React from "react";

function QuienesSomos() {
  return (
    <div className="card-body">
      <h3 className="mb-6 text-6xl font-bold">Quiénes Somos</h3>
      <div className="card card-side bg-base-100 shadow-xl flex">
        <figure className="w-1/2">
          <img
            src={require("../imagenes/misión.jpg")}
            alt="Soluiciones Web"
            className="w-full h-full object-cover rounded-xl shadow-lg"
          />
        </figure>
        <div className="w-1/2 text-center flex flex-col items-center justify-center p-4">
          <h2 className="card-title mb-4">Misión</h2>
          <p>
            Brindar soluciones creativas y eficientes para satisfacer las
            necesidades de nuestros clientes.
          </p>
        </div>
      </div>
      <div className="card card-side bg-base-100 shadow-xl flex">
        <figure className="w-1/2">
          <img
            src={require("../imagenes/visión.jpg")}
            alt="Soluiciones Web"
            className="w-full h-full object-cover rounded-xl shadow-lg"
          />
        </figure>
        <div className="w-1/2 text-center flex flex-col items-center justify-center p-4">
          <h2 className="card-title mb-4">Visión</h2>
          <p>
            Volvernos la empresa líder en soluciones digitales, todo a un costo
            justo y accesible para nuevos emprendedores.
          </p>
        </div>
      </div>
      <div className="card card-side bg-base-100 shadow-xl flex">
        <figure className="w-1/2">
          <img
            src={require("../imagenes/valores.jpg")}
            alt="Soluiciones Web"
            className="w-full h-full object-cover rounded-xl shadow-lg"
          />
        </figure>
        <div className="w-1/2 text-center flex flex-col items-center justify-center p-4">
          <h2 className="card-title mb-4">Valores</h2>
          <p>
            Transparencia en cada proceso de tu desarrollo, costos justos y
            atención personalizada.
          </p>
        </div>
      </div>
    </div>
  );
}

export default QuienesSomos;
